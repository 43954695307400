<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="eocdFault" @clear="clearDateRange()">
      <template slot="adSearch">
        <vm-search :label="$t('common.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('event.safetyHazardTime')">
          <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
            :unlink-panels="true" :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')" :default-time="['00:00:00', '23:59:59']" @change="handleDateChange">
          </el-date-picker>
        </vm-search>
      </template>
      <el-table-column prop="elevatorNo" :label="$t('event.elevatorNo')" width="140px" align="center">
        <template v-slot="scope">
          {{ scope.row.elevatorNo }}
        </template>
      </el-table-column>
      <el-table-column prop="elevatorName" show-overflow-tooltip :label="$t('event.elevatorName')" width="140"></el-table-column>
      <el-table-column prop="useUnit" :label="$t('event.useUnit')" width="140" show-overflow-tooltip></el-table-column>
      <el-table-column prop="dtuCode" :label="$t('event.dtuNumber')" width="115px" align="center"></el-table-column>
      <el-table-column prop="faultCode" :label="$t('event.safetyHazardCode')" width="105px"
        align="center"></el-table-column>
      <el-table-column prop="faultDesc" show-overflow-tooltip :label="$t('event.safetyHazardDesc')"></el-table-column>
      <el-table-column prop="startFloor" :label="$t('event.startFloor')" width="90px" align="center"></el-table-column>
      <el-table-column prop="endFloor" :label="$t('event.endFloor')" width="90px" align="center"></el-table-column>
      <el-table-column prop="runDistance" :label="$t('event.runningDistance')" width="120px"
        align="center"></el-table-column>
      <el-table-column prop="vmax" :label="$t('event.maxSpeed')" width="110px" align="center"></el-table-column>
      <el-table-column prop="amax" :label="$t('event.maxAcceleration')" width="130px" align="center"></el-table-column>
      <el-table-column prop="jmax" :label="$t('event.maxVariableAcceleration')" width="160px"
        align="center"></el-table-column>
      <el-table-column prop="faultTime" :label="$t('event.safetyHazardTime')" width="160px"
        align="center"></el-table-column>
      <el-table-column :label="$t('common.operation')" width="80px" align="center">
        <template slot-scope="scope">
          <el-button v-if="scope.row.vibrationId" type="text" @click="handleCurve2(scope.row.vibrationId)">
            {{ $t("vibrationData.curve") }}
          </el-button>
        </template>
      </el-table-column>
    </vm-table>
    <vibration-curve ref="vibrationCurvePage"></vibration-curve>
  </div>
</template>
<script>
import VibrationCurve from "@/views/elevator/VibrationCurve";

export default {
  components: { VibrationCurve },
  data() {
    return {
      loading: false,
      search: {
        filter: "",
        startTime: "",
        endTime: "",
      },
      dateRange: [],
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    clearDateRange() {
      this.dateRange = [];
    },
    handleCurve2(vibrationId) {
      this.$refs.vibrationCurvePage.open(vibrationId);
    },
    handleDateChange(dateRange) {
      if (dateRange != null) {
        this.search.startTime = dateRange[0];
        this.search.endTime = dateRange[1];
      } else {
        this.search.startTime = "";
        this.search.endTime = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
